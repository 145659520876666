import {initTriggerMenu, initTriggerDropdown} from "../../js/nav-menu/nav-menu";
import {initProjectsSlider} from "../../js/projects-slider/projects-slider";
import {initSignSvgAnimation} from "../../js/sign-svg-animation/sign-svg-animation";
import {showLoader, hideLoader} from "../../js/loader/loader";
import {initQuestionForm} from "../../js/questions/questions";

showLoader();

document.addEventListener('DOMContentLoaded', () => {
    initTriggerMenu();
    initTriggerDropdown();

    initProjectsSlider();

    initSignSvgAnimation();

    initQuestionForm();

    setTimeout(hideLoader, 1500);
});
