import {showLoader, hideLoader} from "../../loader/loader";
import {getSwalMessage} from "../../sweetalert-messages/sweetalert-messages";
import Swal from 'sweetalert2';

function submitForm() {
    var frm = document.getElementById('quote-form');
    frm.reset();  // Reset all form data
}

export class FormValidator {
    constructor(formElement, formKey, url = '', customForm = false) {
        this.formElement = formElement;
        this.customForm = customForm;
        this.settings = {
            'domain': url !== '' ? base_url + url : base_url,
            'formId': this.formElement.id,
            'formKey': formKey,
            'email': null
        };

        this.init();
    };

    setupEvtListeners = () => {
        const elements = Array.from(this.formElement.elements).filter((cur) => {
            return cur.localName !== 'button';
        });

        if (this.formElement) {
            this.formElement.addEventListener('submit', this.formSubmit);
        }

        const selects = Array.from(elements).filter(cur => {
            return cur.localName === 'select';
        });

        if (selects) {
            selects.forEach(cur => {
                cur.addEventListener('change', () => {
                    const selectrSelected = cur.parentElement.querySelector('.selectr-selected');

                    if (selectrSelected && selectrSelected.classList.contains('validate-error')) {
                        selectrSelected.classList.remove('validate-error');
                        cur.classList.remove('validate-error');
                    }
                });
            })
        }

        this.emailValidateListener(elements);

        this.errorListeners(elements);

        this.phoneFormatListener(elements);
    };

    init = () => {
        this.setupEvtListeners();
    };

    validateForEmpty = elements => {
        let isValid = true;

        elements.forEach((element) => {
            if (element.value === '' && element.dataset.required === 'true') {
                element.classList.add('validate-error');

                isValid = false;
            }

            if (element.tagName === 'SELECT' && element.dataset.required === 'true' && element.value === '') {
                const selectrSelected = element.parentElement.querySelector('.selectr-selected');

                if (selectrSelected) {
                    selectrSelected.classList.add('validate-error');
                }

                isValid = false;
            }
        });

        if (!isValid) {
            hideLoader();
            Swal.fire(getSwalMessage('error'))
        }

        return isValid;
    };

    isEmailValid = input => {
        const regExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regExp.test(input.value);
    };

    validateEmail = (elements) => {
        let isValid = true;

        elements.forEach(cur => {
            if (cur.type === 'email') {

                if (!this.isEmailValid(cur)) {
                    cur.classList.add('validate-error');
                    isValid = false;
                }
            }
        });

        if (!isValid) {
            hideLoader();
            Swal.fire(getSwalMessage('customError', 'Please enter a valid email address.'));
        }

        return isValid;
    };

    formSubmit = e => {
        e.preventDefault();
        showLoader();

        const $this = e.target;
        const formElems = Array.from($this.elements).filter((cur) => {
            return cur.localName !== 'button';
        });

        this.settings['email'] = $this.querySelector(`input[type="email"]`).value;

        if (this.isFormValid(formElems)) {
            if (this.customForm) {
                this.sendCustomForm(this.formElement);
            } else {
                _simpleForms.send(this.settings, this.formHandler);
            }
        }
    };

    isFormValid = elements => {
        let isValid = true;

        if (!this.validateForEmpty(elements)) {
            isValid = false;
            return;
        }

        if (!this.validateEmail(elements)) {
            isValid = false;
            return;
        }

        return isValid;
    };

    emailValidateListener = elements => {
        elements.forEach(cur => {
            if (cur.type === 'email') {
                cur.addEventListener('input', (e) => {
                    const $this = e.target;

                    if (this.isEmailValid($this.value)) {
                        $this.classList.remove('validate-error');
                    } else {
                        $this.classList.add('validate-error');
                    }
                });
            }
        });

        elements.forEach(cur => {
            if (cur.type === 'email') {
                cur.addEventListener('blur', () => {
                    if (cur.classList.contains('validate-error')) {
                        cur.classList.remove('validate-error');
                    }
                });
            }
        });
    };

    errorListeners = elements => {
        elements.forEach(cur => {
            cur.addEventListener('focus', () => {
                if (cur.classList.contains('validate-error')) {
                    cur.classList.remove('validate-error');
                }
            });
        });
    };

    phoneFormatListener = elements => {
        elements.forEach(cur => {
            if (cur.type === 'tel') {
                cur.addEventListener('input', function (e) {
                    var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                    e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
                });
            }
        })
    };
    

    formHandler = response => {
        try {
            var obj = JSON.parse(response);

            if (obj.status == true) {
                hideLoader();
                Swal.fire(getSwalMessage('success'));

                this.formElement.reset();
            } else {
                hideLoader();
                Swal.fire(getSwalMessage('customError', obj.message));
            }
        } catch (e) {
            hideLoader();
            Swal.fire(getSwalMessage('default'));
        }
    };

    sendCustomForm = form => {
        const data = new FormData(form);
        const ajax = new XMLHttpRequest();

        ajax.open("POST", this.settings.domain, true);

        ajax.onload = function () {
            if (this.status === 200 && this.readyState === 4) {
                try {
                    const response = JSON.parse(this.responseText);
                    if (response.status) {
                        hideLoader();
                        Swal.fire(getSwalMessage('success'));
                        submitForm();
                    } else {
                        hideLoader();
                        Swal.fire(getSwalMessage('customError', response.message));
                    }
                } catch (e) {
                    hideLoader();
                    Swal.fire(getSwalMessage('error'));
                    console.warn(e);
                }
            } else {
                hideLoader();
                Swal.fire(getSwalMessage('error'));
            }
        };

        ajax.send(data);
    };

    
}
