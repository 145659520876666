import anime from 'animejs/lib/anime.es.js';

export const initSignSvgAnimation = () => {
    // anime({
    //     targets: '.how-its-made__pic .sign-svg path',
    //     strokeDashoffset: [anime.setDashoffset, 0],
    //     easing: 'easeInOutSine',
    //     duration: 1500,
    //     delay: function(el, i) { return i * 250 },
    //     direction: 'alternate',
    //     loop: true
    // });

    anime({
        targets: '.how-its-made__pic .sign-svg path',
        scale: [
            { value: .1, easing: 'easeOutSine', duration: 700 },
            { value: 1, easing: 'easeInOutQuad', duration: 1000 }
        ],
        delay: anime.stagger(800, {
            grid: [20, 20],
            from: 'center'
        }),
        direction: 'reverse',
        loop: true,
    });

    anime.timeline({
        delay: 800,
        endDelay: 1000,
        easing: 'easeInOutQuad',
        direction: 'alternate',
        loop: true
    })
    .add({ targets: '.how-its-made__pic .sign-svg .color-red',  fill: '#D33333' }, 0)
    .add({ targets: '.how-its-made__pic .sign-svg .color-dark-red',  fill: '#661D1D' }, 0)
    .add({ targets: '.how-its-made__pic .sign-svg .color-black-red',  fill: '#351515' }, 0);
};
