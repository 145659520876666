export const getSwalMessage = (type, text = '') => {

    const swalMessages = {
        success: {
            title: 'Success',
            // icon: 'success',
            html: '<span class="swal2-text">Form was sent successfully.</span>' +
                '<span class="swal2-text">Please check your email.</span>' +
                '<span class="swal2-text">Thank you!</span>',
            background: `url(${base_url}/images/popup-background--success.png) center no-repeat #e0e0e0`,
            showConfirmButton: false,
            showCloseButton: true,
            customClass: {
                title: 'success',
                closeButton: 'success'
            }
        },
        error: {
            title: 'Ooops',
            // icon: 'error',
            html: 'One or more of the required fields are missing.',
            background: `url(${base_url}/images/popup-background.png) center no-repeat #e0e0e0`,
            customClass: {
                confirmButton: 'swal2-button',
                cancelButton: 'swal2-button',
            },
            confirmButtonText: 'Try again',
            focusConfirm: false,
            showCloseButton: true,
        },
        customError: {
            title: 'Ooops',
            // icon: 'error',
            html: `${text}`,
            background: `url(${base_url}/images/popup-background.png) center no-repeat #e0e0e0`,
            customClass: {
                confirmButton: 'swal2-button',
                cancelButton: 'swal2-button',
            },
            confirmButtonText: 'Try again',
            focusConfirm: false,
            showCloseButton: true,
        },
        default: {
            title: 'Ooops',
            // icon: 'error',
            html: '<span class="swal2-text">Something went wrong.</span>' +
                '<span class="swal2-text">Let\'s try one more again.</span>',
            background: `url(${base_url}/images/popup-background.png) center no-repeat #e0e0e0`,
            customClass: {
                confirmButton: 'swal2-button',
                cancelButton: 'swal2-button',
            },
            confirmButtonText: 'Try again',
            focusConfirm: false,
            showCloseButton: true,
        }
    };

    return swalMessages[type] || swalMessages.default;
};
