const backdrop = document.querySelector('.js_backdrop') || '';
const content = backdrop !== '' ? backdrop.querySelector('.backdrop__content') : '';
const html = document.querySelector('html');

export const addXOffset = () => {
    const xDifference = window.innerWidth - html.offsetWidth;
    html.style.overflow = 'hidden';
    html.style.paddingRight = `${xDifference}px`;
};

export const removeXOffset = () => {
    html.style.overflow = 'visible';
    html.style.paddingRight = `0`;
};

const openBackdrop = (block) => {
    clearBackdrop();

    if (block) {
        insertBlock(block);
        backdrop.classList.add('active');
        content.classList.add('active');
        addXOffset();
    }
};

const insertBlock = (block) => {
    content.insertAdjacentHTML('afterbegin', block);
};

const closeBackdrop = () => {
    content.classList.remove('active');
    backdrop.classList.remove('active');

    setTimeout(() => {

    }, 100);

    setTimeout(() => {
        removeXOffset();
    }, 300)
};

const clearBackdrop = () => {
    content.innerHTML = '';
};

export {openBackdrop, closeBackdrop, clearBackdrop};

const closeOnClickHandler = e => {
  const clickedTarget = e.target;

  if (clickedTarget === backdrop) {
      closeBackdrop();
  }
};

const setupEventListeners = () => {
    backdrop.addEventListener('click', closeOnClickHandler)
};

export const initBackdrop = () => {
    setupEventListeners();
};