import {addXOffset, removeXOffset} from "../common/backdrop/backdrop";

const menuTriggers = document.querySelectorAll('.js_menu_trigger');
const menuList = document.querySelector('.nav-menu__list');

const menuHandler = e => {
    e.preventDefault();

    menuTriggers.forEach(item => {
        item.classList.toggle('hide');
    });
    menuList.classList.toggle('show');
    if (menuList.classList.contains('show')) {
        addXOffset();
    } else {
        removeXOffset();
    }
};

export const initTriggerMenu = () => {
    menuTriggers.forEach(menuTrigger => {
        menuTrigger.addEventListener('click', menuHandler);
    });
};


/* show dropdown menu after arrow click */
const triggerDropdown = document.querySelectorAll('.js_show_dropdown');

const triggerDropdownHandler = e => {
    const target = e.target;
    target.closest('.js_show_dropdown').classList.toggle('active');
    // target.closest('.nav-menu__item').querySelector('.dropdown-menu').classList.toggle('show');
};

export const initTriggerDropdown = () => {
    triggerDropdown.forEach(triggerItem => {
        triggerItem.addEventListener('click', triggerDropdownHandler);
    });
};