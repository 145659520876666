import Swiper from 'swiper';

const projectsSliderEl = document.querySelector('.projects__slider');

export const initProjectsSlider = () =>  {
    const projectsSlider = new Swiper(projectsSliderEl, {
        slidesPerView: 6,
        spaceBetween: 20,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            1439: {
                slidesPerView: 5,
            },
            1199: {
                slidesPerView: 4,
            },
            991: {
                slidesPerView: 3,
            },
            767: {
                slidesPerView: 2,
            },
            575: {
                slidesPerView: 1,
            }
        },
    });
};