import {addXOffset, removeXOffset} from "../common/backdrop/backdrop";

const loaderWrapper = document.querySelector('.page-loader');
const loaderLetters = loaderWrapper.querySelectorAll('.loader__letter');

// const letterChange = wrapper => {
//     let letterIndex = 0;
//     let isLetterChange = false;
//     const interval = 500;
//     const letters = wrapper.querySelectorAll('.loader__letter');
//
//     return {
//         init() {
//             this.isChange(true);
//             this.next();
//         },
//         reset() {
//             this.isChange(false);
//             this.setIndex(0);
//         },
//         next() {
//             const letter = letters[letterIndex] || '';
//
//             if (isLetterChange) {
//                 if (letterIndex < letters.length) {
//                     this.hideAll();
//                     letter.classList.add('loader__letter--show');
//                     letterIndex++;
//                 } else {
//                     letterIndex = 0;
//                 }
//
//                 setTimeout(() => {
//                     this.next();
//                 }, interval);
//             }
//         },
//         hideAll() {
//             letters.forEach(letter => letter.classList.remove('loader__letter--show'));
//         },
//         setIndex(index) {
//             letterIndex = index;
//         },
//         isChange(bool) {
//             isLetterChange = bool;
//         }
//     }
// };
//
// const instanceLetterChange = letterChange(loaderWrapper);

export const showLoader = () => {
    addXOffset();
    loaderWrapper.classList.add('page-loader--show');
    loaderLetters.forEach(letter => {
        letter.classList.add('fadeInOut')
    });
    // instanceLetterChange.init();
};

export const hideLoader = () => {
    removeXOffset();
    loaderWrapper.classList.remove('page-loader--show');
    loaderLetters.forEach(letter => {
        letter.classList.remove('fadeInOut')
    });
    // instanceLetterChange.reset();
};
